
import beresford from '../assets/beresford.png'
import bickfords from '../assets/projects-bickfords.png'
import WheelBarrow from '../assets/w&b cart page refresh.png'
import yerbi from '../assets/yerbi.png'
import restaurant from '../assets/Modern UI UX Restaurant.png'

export default {
    beresford,
    bickfords,
    WheelBarrow,
    yerbi,
    restaurant
};